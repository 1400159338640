<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__user">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <el-form-item>
          <el-input
            v-model="dataForm.name"
            :placeholder="$t('custom.name')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.mobile"
            :placeholder="$t('custom.mobile')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.certificateNo"
            :placeholder="$t('custom.certificateNo')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button v-if="$hasPermission('sys:user:save')" type="primary" @click="addOrUpdateHandle()">{{ $t('add') }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="$hasPermission('sys:user:delete')" type="danger" @click="deleteHandle()">{{ $t('deleteBatch') }}</el-button>
        </el-form-item> -->
        <!-- <el-form-item>
          <el-button v-if="$hasPermission('sys:user:export')" type="info" @click="exportHandle()">{{ $t('export') }}</el-button>
        </el-form-item> -->
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%"
      >
        <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="id"
          :label="$t('custom.id')"
          sortable="custom"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="nickName"
          :label="$t('custom.nickName')"
          sortable="custom"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$t('custom.name')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="certificateType"
          :label="$t('custom.certificateType')"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ $getDictLabel('certificateType', scope.row.certificateType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="certificateNo"
          :label="$t('custom.certificateNo')"
          header-align="center"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="area" :label="$t('custom.area')" header-align="center" align="center"></el-table-column> -->
        <el-table-column
          prop="birthDate"
          :label="$t('custom.birthDate')"
          header-align="center"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="email" :label="$t('custom.email')" header-align="center" align="center"></el-table-column> -->
        <el-table-column
          prop="mobile"
          :label="$t('custom.mobile')"
          sortable="custom"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="gender"
          :label="$t('custom.gender')"
          sortable="custom"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ $getDictLabel('gender', scope.row.gender) }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="height" :label="$t('custom.height')" sortable="custom" header-align="center" align="center"></el-table-column> -->
        <!-- <el-table-column prop="weight" :label="$t('custom.weight')" sortable="custom" header-align="center" align="center"></el-table-column> -->
        <!-- <el-table-column prop="orgIdentify" :label="$t('custom.orgIdentify')" sortable="custom" header-align="center" align="center"></el-table-column> -->
        <!-- <el-table-column prop="orgName" :label="$t('custom.orgName')" sortable="custom" header-align="center" align="center"></el-table-column> -->
        <el-table-column
          prop="lastLoginTime"
          :label="$t('custom.lastLoginTime')"
          sortable="custom"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userType"
          :label="$t('custom.userType')"
          sortable="custom"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          :label="$t('custom.status')"
          sortable="custom"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            {{ $getDictLabel('userStatus', scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="appCode"
          :label="$t('custom.appCode')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="140">
          <template slot-scope="scope" v-if="scope.row.mobile">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >健康档案</el-button
            >
            <el-button @click="handleRemind(scope.row)" type="text" size="small"
              >健康提醒</el-button
            >
          </template>
        </el-table-column>
        <!-- <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="150">
          <template slot-scope="scope">
            <el-button v-if="$hasPermission('sys:custom:update')" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">{{ $t('update') }}</el-button>
            <el-button v-if="$hasPermission('sys:custom:delete')" type="text" size="small" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update
        v-if="addOrUpdateVisible"
        ref="addOrUpdate"
        @refreshDataList="getDataList"
      ></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
// import AddOrUpdate from './user-add-or-update'
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/user/page',
        getDataListIsPage: true,
        deleteURL: '/sys/user',
        deleteIsBatch: true,
        exportURL: '/sys/user/export',
      },
      postList: [],
      dataForm: {
        name: '',
        mobile: '',
        certificateNo: '',
      },
    };
  },
  // components: {
  //   AddOrUpdate
  // },
  created() {
    this.getPostList();
  },
  methods: {
    handleClick({ id }) {
      this.$router.push({
        path: '/healthRecord/secondery',
        query: {
          userId: id,
        },
      });
    },
    handleRemind(item) {
      sessionStorage.setItem('heealth_reminder_item', JSON.stringify(item));
      this.$router.push({
        path: '/healthReminder/detail',
      });
    },
    getPostList() {
      this.$http
        .get('/user/page')
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.postList = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>
